<script setup lang="ts">
import "./v2.css"
import { useIntl } from "~/composables/intl"
import { navigateTo, useAuth, useRoute } from "#imports"
import { onMounted, effect, ref, watch, computed } from "vue"
import { TickersV3Rsc } from "#resources"
import { clientFor, run, useSafeSuspenseQuery } from "~/composables/client"
import { NonEmptyString255 } from "effect-app/Schema"
import { Effect } from "effect"
import { useClarity } from "~/composables/clarity"
import HeaderV3 from "~/components/HeaderV3.vue"
import type { TickerSearchItemV3 } from "#resources/TickersV3"
import {
  mdiLogout,
  mdiHelp,
  mdiCog,
  mdiWallet,
  mdiShopping,
  mdiMagnify,
  mdiApplication,
} from "@mdi/js"
import { isMobile } from "~/composables/useDevice"
import { useAuthUser } from "~/composables/currentUser"

const mobileView = isMobile()

const route = useRoute()

const sess = useAuth()

const { locale } = useIntl()

const { trans } = useIntl()
// Monitor the access token, if it's about to expire, do a token refresh?
onMounted(() => {
  // TODO: Instead of doing this every hour, do it on api client authentication error
  // and retry the request.. ask the user if we can reload to login?
  setInterval(() => sess.getSession(), 1 * 60 * 60 * 1000)
})

const clarity = useClarity()

const isAdmin = computed(() => route.path.startsWith("/admin"))

const isMobileView = isMobile()

const useMobileBottomNavigation = computed(
  () => isMobileView.value && !isAdmin.value,
)

// on refresh error, go login
effect(() => {
  if (sess.data.value?.error === "RefreshAccessTokenError") {
    if (!window.confirm(trans("session.expired"))) {
      return
    }
    void sess.signIn("auth0", undefined, {
      // don't display the login prompt if already logged-in at auth0
      prompt: "",
    }) // Force sign in to hopefully resolve error
  }
})

const query = ref<string>("")
const queryToSearch = ref<string>("")

const tickersClient = clientFor(TickersV3Rsc)

// TODO: This is repeated across multiple pages, move to a composable?
const wrappedSearch = {
  ...tickersClient.SearchV3,
  handler: (query: string) =>
    query
      ? tickersClient.SearchV3.handler({
          query: NonEmptyString255(query),
        }).pipe(
          Effect.tap(
            queryToSearch.value
              ? clarity.emitCustomEventLogError("SecuritySearch")
              : Effect.void,
          ),
          Effect.map(_ => _.items),
        )
      : tickersClient.HighlightsV3.handler.pipe(Effect.map(_ => _.items)),
}

const [_, securities] = await run(
  useSafeSuspenseQuery(wrappedSearch, queryToSearch, {
    refetchOnWindowFocus: false,
  }),
)

let debounceTimeout: number | undefined

watch(query, () => {
  clearTimeout(debounceTimeout)
  debounceTimeout = window.setTimeout(() => {
    queryToSearch.value = query.value
  }, 500)
})

const isDrawerOpen = ref(false)

const onSecurityClick = (security: TickerSearchItemV3) => {
  navigateTo(`/securities/${security.symbol}`)
}

const user = useAuthUser()

const role = computed(() => user.role)
</script>

<template>
  <v-app>
    <v-navigation-drawer
      v-if="mobileView"
      v-model="isDrawerOpen"
      location="right"
      style="background-color: rgb(18, 18, 18)"
    >
      <v-list>
        <v-list-item>
          <NuxtLink :to="{ name: 'settings' }" class="d-flex">
            <span class="header-icon">
              <v-icon :icon="mdiCog" />
            </span>
            {{ trans("body.settings") }}
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'support' }" class="d-flex">
            <span class="header-icon">
              <v-icon :icon="mdiHelp" />
            </span>
            Support
          </NuxtLink>
        </v-list-item>
        <v-list-item v-if="role === 'manager' && !isAdmin">
          <NuxtLink :to="{ name: 'admin' }" class="d-flex">
            <span class="header-icon">
              <v-icon :icon="mdiCog" />
            </span>
            Admin
          </NuxtLink>
        </v-list-item>
        <v-list-item v-if="role === 'manager' && isAdmin">
          <NuxtLink :to="{ name: 'index' }" class="d-flex">
            <span class="header-icon">
              <v-icon :icon="mdiApplication" />
            </span>
            Application
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink
            :to="{ name: 'index' }"
            class="d-flex"
            @click="() => sess.signOut()"
          >
            <span class="header-icon">
              <v-icon :icon="mdiLogout" />
            </span>
            Logout
          </NuxtLink>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <HeaderV3
      v-model:query="query"
      :securities="securities"
      :locale="locale"
      :is-admin-area="isAdmin"
      @toggle-drawer="isDrawerOpen = !isDrawerOpen"
      @security-click="onSecurityClick"
    />
    <v-main>
      <Suspense :timeout="500">
        <slot />
        <template #fallback>
          <Loader />
        </template>
      </Suspense>
    </v-main>
    <v-bottom-navigation
      v-if="useMobileBottomNavigation"
      location="bottom start"
      grow
      style="left: 0; right: 0; width: 100%; z-index: 1050"
      color="primary"
      class="bottom-navigation"
    >
      <v-btn
        :to="{ name: 'portfolio' }"
        class="d-flex bottom-navigation-button"
        value="portfolio"
      >
        <v-icon :icon="mdiWallet"></v-icon>
        Portfolio
      </v-btn>
      <v-btn
        :to="{ name: 'securities' }"
        class="d-flex bottom-navigation-button"
        value="securities"
      >
        <v-icon :icon="mdiShopping"></v-icon>
        Securities
      </v-btn>
      <v-btn
        :to="{ name: 'search' }"
        class="d-flex bottom-navigation-button"
        value="index"
      >
        <v-icon :icon="mdiMagnify"></v-icon>
        Search
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<style lang="scss">
.header-icon {
  width: 20px;
  height: 20px;
  svg {
    stroke: white;
    fill: white;
  }
}

a.router-link-exact-active {
  color: white;
  font-weight: 400;
}

a.router-link-exact-active::after {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 30px;
  right: 30px;
  height: 1px;
  background-color: rgb(48, 48, 48);
}

.bottom-navigation {
  background-color: rgb(18, 18, 18);
  z-index: 1050;
  .bottom-navigation-button {
    max-width: unset !important;
  }
}
</style>

