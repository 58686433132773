<template>
  <v-list class="securities-list">
    <v-list-item
      v-for="(item, index) in groupedSecurities"
      :key="item.type + index"
      :type="item.type"
      :disabled="item.type !== 'security'"
      @click="
        () => item.type === 'security' && emit('security-click', item as TickerSearchItemV3)
      "
    >
      <template v-if="item.type === 'security'" #prepend>
        <TickerLogo
          :logo="(item as TickerSearchItemV3).logo"
          :symbol="(item as TickerSearchItemV3).symbol"
          :dimensions="40"
          class="mr-2"
        />
      </template>
      <v-list-item-title v-if="item.type !== 'divider'">
        {{ item.title }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="item.type === 'security'">
        {{
        (item as TickerSearchItemV3).symbol
        }}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { TickerSearchItemV3 } from "#resources/TickersV3"
import TickerLogo from "../TickerLogo.vue"

const props = defineProps<{
  securities: readonly TickerSearchItemV3[]
  locale: "de" | "en"
}>()

const groupedSecurities = computed(() => {
  const hasGroupNames = props.securities.some(
    (ticker): ticker is TickerSearchItemV3 & { groupName: string } =>
      ticker.groupName !== null,
  )

  if (!hasGroupNames) {
    return props.securities.map(ticker => ({
      type: "security",
      title: ticker.name,
      subtitle: ticker.symbol,
      value: ticker.symbol,
      ...ticker,
    }))
  }

  // group them by groupName
  return Object.entries(
    props.securities
      .filter(
        (ticker): ticker is TickerSearchItemV3 & { groupName: string } =>
          ticker.groupName !== null,
      )
      .reduce(
        (acc, curr) => {
          acc[curr.groupName] = [...(acc[curr.groupName] ?? []), curr]
          return acc
        },
        {} as Record<string, TickerSearchItemV3[]>,
      ),
  ).flatMap(([groupName, securities]) => [
    {
      type: "subheader",
      title: securities[0]?.groupName ?? groupName,
    },
    ...securities.map(ticker => ({
      type: "security",
      title: ticker.name,
      subtitle: ticker.symbol,
      value: ticker.symbol,
      ...ticker,
    })),
  ])
})

const emit = defineEmits<{
  (e: "security-click", security: TickerSearchItemV3): void
}>()
</script>

<style lang="scss" scoped>
.securities-list {
  .v-list-item--disabled {
    opacity: 1;
  }
}
</style>
