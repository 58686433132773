<script setup lang="ts">
import {
  mdiMenu,
  mdiBell,
  mdiHelp,
  mdiCog,
  mdiViewDashboard,
  mdiLogout,
  mdiApplication,
} from "@mdi/js"
import { computed, ref } from "vue"
import SearchInputDropdown from "./Search/SearchInputDropdown.vue"
import PortfolioIcon from "./Icons/PortfolioIcon.vue"
import NewspaperIcon from "./Icons/NewspaperIcon.vue"
import MarketIcon from "./Icons/MarketIcon.vue"
import UserIcon from "./Icons/UserIcon.vue"
import type { TickerSearchItemV3 } from "#resources/TickersV3"
import { isMobile } from "~/composables/useDevice"
import { useAuthUser } from "~/composables/currentUser"

defineEmits<{
  (e: "toggleDrawer"): void
  (e: "security-click", security: TickerSearchItemV3): void
}>()

const mobileView = isMobile()

const query = defineModel<string | null>("query", { required: true })

const props = defineProps<{
  securities: readonly TickerSearchItemV3[]
  locale: "de" | "en"
  isAdminArea: boolean
}>()

// TODO: Add notifications
const areNotificationsEnabled = ref(false)
const areNewsEnabled = ref(false)

const user = useAuthUser()

const items = computed(() => [
  {
    title: "Settings",
    to: "/settings",
    icon: mdiCog,
  },
  {
    title: "Support",
    to: "/support",
    icon: mdiHelp,
  },
  ...(user.role === "manager"
    ? !props.isAdminArea
      ? [
          {
            title: "Admin",
            to: "/admin",
            icon: mdiViewDashboard,
          },
        ]
      : [{ title: "Customerz", to: "/", icon: mdiApplication }]
    : []),
  {
    title: "Logout",
    to: "/logout",
    icon: mdiLogout,
  },
])
</script>

<template>
  <v-app-bar
    v-if="mobileView"
    app
    density="compact"
    class="px-4 app-bar-mobile"
    elevation="0"
  >
    <template #prepend>
      <NuxtLink :to="{ name: 'index' }">
        <span class="main-link">
          <img src="@/assets/svg.svg" alt="get signalz logo" />
        </span>
      </NuxtLink>
    </template>
    <v-spacer />
    <v-btn v-if="areNotificationsEnabled" :icon="mdiBell" />
    <v-btn
      :icon="mdiMenu"
      style="margin-inline-end: 0"
      density="compact"
      @click="$emit('toggleDrawer')"
    />
  </v-app-bar>
  <v-app-bar
    v-else
    app
    density="default"
    class="d-flex align-center app-bar-large"
    elevation="0"
  >
    <v-container>
      <v-row class="d-flex align-center">
        <v-col class="d-flex align-center">
          <NuxtLink :to="{ name: 'index' }">
            <span class="main-link">
              <img src="@/assets/svg.svg" alt="get signalz logo" />
            </span>
          </NuxtLink>
          <div
            style="width: 300px; height: 100%"
            class="d-flex align-center ml-4"
          >
            <div style="width: 100%">
              <SearchInputDropdown
                v-model:query="query"
                :securities="props.securities"
                :locale="props.locale"
                @security-click="$emit('security-click', $event)"
              />
            </div>
          </div>
        </v-col>
        <v-spacer />
        <v-col class="d-flex align-center justify-end">
          <v-btn
            v-if="areNewsEnabled"
            to="/news"
            variant="plain"
            class="px-0 header-icon-btn"
          >
            <span class="header-icon">
              <NewspaperIcon />
            </span>
          </v-btn>
          <v-btn to="/portfolio" variant="plain" class="px-0 header-icon-btn">
            <span class="header-icon">
              <PortfolioIcon />
            </span>
          </v-btn>
          <v-btn to="/securities" variant="plain" class="px-0 header-icon-btn">
            <span class="header-icon">
              <MarketIcon />
            </span>
          </v-btn>
          <v-btn
            v-if="areNotificationsEnabled"
            :icon="mdiBell"
            variant="plain"
            class="px-0"
          />
          <v-btn variant="plain" class="px-0 header-icon-btn">
            <span class="header-icon">
              <UserIcon />
            </span>
            <v-menu activator="parent" location="bottom end" rounded="xl">
              <v-list class="py-0">
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  :value="index"
                  :to="item.to"
                >
                  <v-list-item-title>
                    <v-icon :icon="item.icon" class="mr-2" />
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<style lang="scss">
.header-icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
  svg {
    width: 20px;
    height: 20px;
    stroke: white;
    fill: white;
  }
}

.header-icon-btn:last-child {
  margin-right: 0;
}

// Remove margin from last button provided by vuetify
.app-bar-large .v-btn:last-child {
  margin-inline-end: 0px;
}

header.app-bar-large.v-app-bar {
  background-color: rgb(18, 18, 18);
}

header.app-bar-mobile.v-app-bar {
  background-color: rgb(18, 18, 18);
}

.header-icon-btn {
  min-width: unset;
}
</style>
