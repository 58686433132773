<template>
  <div class="search-input-dropdown">
    <Input
      v-model:query="query"
      density="compact"
      :spellcheck="false"
      @focus="showOverlay = true"
      @blur="onBlur"
    />
    <Teleport to="body">
      <v-card
        v-show="showOverlay"
        :width="inputWidth"
        class="rounded-lg securities-list-card-container"
        :style="{
          top: `${bottomCoordinate + 16}px`,
          left: `${leftCoordinate}px`,
        }"
        elevation="4"
      >
        <SecuritiesList
          :securities="filteredSecurities"
          :locale="locale"
          @security-click="onSecurityClick"
        />
      </v-card>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, onBeforeUnmount } from "vue"
import Input from "./Input.vue"
import SecuritiesList from "./SecuritiesList.vue"
import type { TickerSearchItemV3 } from "#resources/TickersV3"

const props = defineProps<{
  securities: readonly TickerSearchItemV3[]
  locale: "de" | "en"
}>()

const query = defineModel<string | null>("query", { required: true })
const showOverlay = ref(false)

const filteredSecurities = computed(() => {
  return props.securities
})

const emit = defineEmits<{
  (e: "security-click", security: TickerSearchItemV3): void
}>()

const onBlur = () => {
  // Delay hiding the overlay to allow for clicking on the list items
  setTimeout(() => {
    showOverlay.value = false
  }, 200)
}

const onSecurityClick = (security: TickerSearchItemV3) => {
  showOverlay.value = false
  query.value = null
  emit("security-click", security)
}

const inputWidth = ref(0)
const bottomCoordinate = ref(0)
const leftCoordinate = ref(0)

const getTopAndLeftCoordinates = () => {
  const searchInputDropdownElement = document.querySelector(
    ".search-input-dropdown",
  )
  if (searchInputDropdownElement) {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop
    const rect = (
      searchInputDropdownElement as HTMLElement
    ).getBoundingClientRect()
    return {
      bottom: rect.bottom + scrollPosition,
      left: rect.left + document.body.scrollLeft,
    }
  }
  return {
    bottom: 0,
    left: 0,
  }
}

watch(showOverlay, () => {
  const { bottom, left } = getTopAndLeftCoordinates()
  bottomCoordinate.value = bottom
  leftCoordinate.value = left
})

const setCoordinates = () => {
  const { bottom, left } = getTopAndLeftCoordinates()
  bottomCoordinate.value = bottom
  leftCoordinate.value = left
}

onMounted(() => {
  const inputElement = document.querySelector(".search-input-dropdown")
  if (inputElement) {
    inputWidth.value = (inputElement as HTMLElement).offsetWidth

    const { bottom, left } = getTopAndLeftCoordinates()
    bottomCoordinate.value = bottom
    leftCoordinate.value = left
  }

  document.addEventListener("scroll", setCoordinates)
})

onBeforeUnmount(() => {
  document.removeEventListener("scroll", setCoordinates)
})
</script>

<style scoped>
.search-input-dropdown {
  position: relative;
  isolation: isolate;
}
.securities-list-card-container {
  max-height: 60vh;
  position: absolute;
  z-index: 2000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}
</style>
